import React, { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './App'
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import { saveState } from './localStorage'
import { debounce } from './utils'
import './styles/index.css'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

const store = configureStore()
//Save localstorage
store.subscribe(debounce(function () {
  saveState(store.getState())
}, 1000))

const theme = extendTheme({
  fonts: {
    heading: `'ReadexPro','NokaTrial', 'Vazirmatn-Bold', Roboto`,
    body: `'Vazirmatn', Roboto`
  },
  colors: {
    easycar1: {
      "50": "#f3f9f4",
      "100": "#d0e6d3",
      "200": "#a7cfad",
      "300": "#75b47e",
      "400": "#58a463",
      "500": "#338e40",
      "600": "#2b7836",
      "700": "#23602b",
      "800": "#1d5125",
      "900": "#153b1a"
    },
    easycar2: {
      "50": "#949494",
      "100": "#7d7d7d",
      "200": "#696969",
      "300": "#545454",
      "400": "#474747",
      "500": "#333333",
      "600": "#2d2d2d",
      "700": "#212121",
      "800": "#141414",
      "900": "#121212"
    }
  }
})

root.render(
  <StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme} >
        <ColorModeScript />
        <App />
      </ChakraProvider>
    </Provider>
  </StrictMode>
)
