import React from "react"
import { Image } from '@chakra-ui/react'

const SocialButton = ({ src, href, alt }) => {
    return (
        <a href={href} target="_blank" rel="noreferrer">
            <Image className="miniCarIcon" src={src} alt={alt} w={"28px"} h={"28px"} />
        </a>
    )
}


export default SocialButton