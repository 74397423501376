import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom"
import { login } from '../actions'
import { getAuth } from '../reducers'
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    Stack,
    Box,
    Image,
    useColorModeValue
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import Logo from '../multimedia/logo.png'

const Login = (props) => {

    const { login, isAuth } = props
    const white = useColorModeValue('white', 'gray.700')

    if (isAuth) {
        return <Redirect to={'/status'} />
    }

    return <Layout>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Box rounded={'lg'} bg={white} boxShadow={'lg'} p={8}>
                <Image src={Logo} maxH={300} width={"auto"} m={"auto"} />
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validate={values => FormValidator([
                        { field: 'email', value: values.email, rules: ['required', 'email'], lang: 'Correo' },
                        { field: 'password', value: values.password, rules: ['required'], lang: 'Contraseña' }
                    ])}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        Promise.resolve(login(values))
                            .then(setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={errors.email && touched.email}>
                                <FormLabel>Correo</FormLabel>
                                <Input type="email" name='email' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.password && touched.password}>
                                <FormLabel>Contraseña</FormLabel>
                                <Input type={'password'} size="lg" name={"password"} placeholder="*******" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                <FormErrorMessage>{errors.password}</FormErrorMessage>
                            </FormControl>
                            <Button colorScheme="green" type="submit" width="full" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Iniciar sesión'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Stack>
        <br />
        <br />
        <br />
        <br />
        <br />
    </Layout >
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true
})

const mapDispatchToProps = ({ login })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))