import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ImageWithZoom, Dot } from 'pure-react-carousel'
import { APIpath } from '../actions'
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Icon, Image } from '@chakra-ui/react';
import placeholder from '../multimedia/placeholder.jpg'

const CustomRightArrow = () => {
    return <Box display={"flex"} alignItems={"center"} bg={"easycar1.500"} height={"full"} style={{ cursor: "pointer" }}>
        <Icon as={ChevronRightIcon} color={"white"} boxSize={8} />
    </Box>
}

const CustomLeftArrow = () => {
    return <Box display={"flex"} alignItems={"center"} bg={"easycar1.500"} height={"full"} style={{ cursor: "pointer" }}>
        <Icon as={ChevronLeftIcon} color={"white"} boxSize={8} />
    </Box>
}


const CarPreview = (props) => {

    const { id } = props

    const images = [
        { image: `${APIpath}/carro/image/${id}/1` },
        { image: `${APIpath}/carro/image/${id}/2` },
        { image: `${APIpath}/carro/image/${id}/3` },
        { image: `${APIpath}/carro/image/${id}/4` },
        { image: `${APIpath}/carro/image/${id}/5` }
    ]

    const replaceImage = (error) => {
        error.target.src = placeholder;
    }

    return (
        <Box w={"full"}>
            <CarouselProvider
                naturalSlideWidth={750}
                naturalSlideHeight={500}
                totalSlides={5}
                infinite={true}
            >
                <Slider>
                    {images.map((image, i) =>
                        <Slide key={i} index={i}><ImageWithZoom alt={i} src={image.image} /></Slide>
                    )}
                </Slider>
                <Box width={"full"} display={"flex"} justifyContent={"center"} alignContent={"center"} gap={"5px"} mt={"10px"}>
                    <ButtonBack><CustomLeftArrow /></ButtonBack>
                    {images.map((image, i) =>
                        <Dot key={i} slide={i} ><Image w={"120px"} alt={i} src={image.image} borderRadius={"5px"} onError={replaceImage} /></Dot>
                    )}
                    <ButtonNext><CustomRightArrow /></ButtonNext>
                </Box>

            </CarouselProvider>
        </Box>
    )
}

export default CarPreview