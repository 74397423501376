import { Box, Text, Heading, Flex, Image, Button, Tabs, TabList, Tab, TabPanel, TabPanels, Avatar } from '@chakra-ui/react'
import Layout from '../layout'
import image1 from '../multimedia/imagenes/carros.jpg'
import Card1 from '../multimedia/icons/icono1.svg'
import Card2 from '../multimedia/icons/icono2.svg'
import Card3 from '../multimedia/icons/icono3.svg'
import Card4 from '../multimedia/icons/icono4.svg'
import Card5 from '../multimedia/icons/icono6.svg'
import MiniCard from "../components/MiniCard"

const Vende = () => {

    return (
        <Layout>
            <Box w={"full"} >
                <Flex bg={'gray.100'} maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"} mt={"4.5rem"} mb={"2rem"}>
                    <Box flex={1} order={{ base: 2, md: 1 }} p={"10"} margin={"auto"} >
                        <Heading>Vende o intercambia tu vehículo en menos de 24 horas.</Heading>
                        <br />
                        <Text>Descubre lo fácil que es vender tu vehículo en Easycar</Text>
                        <br />
                        <a
                            href={`https://wa.me/+50498493179?text=Hola,%20estoy%20interesado%20en%20vender%20mi%20vehículo.`}
                            target={"_blank"}
                            rel="noreferrer">
                            <Button colorScheme={"easycar1"}>
                                Cotizar vehículo
                            </Button>
                        </a>
                    </Box>
                    <Box flex={1} order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'Conduciendo'} height={"auto"} width={{ base: '100%', md: "100%" }} src={image1} />
                    </Box>
                </Flex>
            </Box>
            <Box textAlign={"center"} w={"full"} mb={"10px"} marginTop={"20px"}>
                <Heading>Descubre lo fácil que es vender tu vehículo en Easycar</Heading>
            </Box>

            <Tabs maxW={"1400px"} variant='soft-rounded' colorScheme='easycar1' padding={"10px"} margin={"auto"} mt={"7.5rem"} mb={"2rem"}>
                <TabList justifyContent={"center"}>
                    <Tab>Vende</Tab>
                    <Tab>Intercambia</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Box textAlign={"center"}>
                            <Flex flexDir={{ base: 'column', lg: 'row' }} alignItems={"center"} justifyContent={"space-around"} pt={"50px"} pb={"50px"} bg={"white"}>
                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card1} height={"50px"} />} />}
                                    title={"Contacta un asesor"}
                                    content={"Uno de nuestros asesores conversará contigo sobre la especificaciones de tu vehículo."}
                                />

                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card2} height={"50px"} />} />}
                                    title={"Elige una oferta"}
                                    content={"Dispondremos varios precios para que selecciones el que mejor te parece."}
                                />

                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card3} height={"50px"} />} />}
                                    title={"Agenda una inspección"}
                                    content={"Será necesario que lleves tu vehículo a uno de nuestros talleres."}
                                />

                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card4} height={"50px"} />} />}
                                    title={"Realiza tu venta"}
                                    content={"En Easycar obtienes el mejor valúo del mercado."}
                                />
                            </Flex>

                            <a
                                href={`https://wa.me/+50498493179?text=Hola,%20estoy%20interesado%20en%20vender%20mi%20vehículo.`}
                                target={"_blank"}
                                rel="noreferrer">
                                <Button colorScheme={"easycar2"}>
                                    Contacta a uno de nuestros asesores
                                </Button>
                            </a>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box textAlign={"center"}>
                            <Flex flexDir={{ base: 'column', lg: 'row' }} alignItems={"center"} justifyContent={"space-around"} pt={"50px"} pb={"50px"} bg={"white"}>
                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card1} height={"50px"} />} />}
                                    title={"Contacta un asesor"}
                                    content={"Uno de nuestros asesores conversará contigo sobre la especificaciones de tu vehículo."}
                                />

                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card2} height={"50px"} />} />}
                                    title={"Elige una oferta"}
                                    content={"Dispondremos varios precios para que selecciones el que mejor te parece."}
                                />

                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card3} height={"50px"} />} />}
                                    title={"Agenda una inspección"}
                                    content={"Será necesario que lleves tu vehículo a uno de nuestros talleres."}
                                />
                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card5} height={"50px"} />} />}
                                    title={"Selecciona tu nuevo vehículo"}
                                    content={"Adquiere el vehículo de tus sueños entre todos nuestras marcas y modelos disponibles."}
                                />

                                <MiniCard
                                    icon={<Avatar className='miniCarIcon' bg={"#f0fdea"} size='xl' icon={<Image src={Card4} height={"50px"} />} />}
                                    title={"Precalifica el monto restante"}
                                    content={"Si el vehículo que escogiste es de mayor monto Easycar te apoyamos."}
                                />
                            </Flex>

                            <a
                                href={`https://wa.me/+50498493179?text=Hola,%20estoy%20interesado%20en%20vender%20mi%20vehículo.`}
                                target={"_blank"}
                                rel="noreferrer">
                                <Button colorScheme={"easycar2"}>
                                    Contacta a uno de nuestros asesores
                                </Button>
                            </a>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>



        </Layout>
    )

}

export default Vende