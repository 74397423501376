import React from "react"
import { connect } from 'react-redux'
import { documentImage } from '../actions'
import { getClient, getAuth } from '../reducers'
import { Formik } from "formik"
import { FormValidator } from '../utils/validationForms'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Button,
    CircularProgress,
    Flex,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Box,
    Image
} from '@chakra-ui/react'
import InputFile from "../components/InputFile"
import Layout from '../layout'
import placeholder from '../multimedia/placeholder.jpg'
import { APIpath } from '../actions'


const Documents = (props) => {

    const { client, documentImage } = props

    const replaceImage = (error) => {
        error.target.src = placeholder;
    }

    return (
        <Layout >
            <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} >
                <Box bg={'white'} rounded={'md'} m={3} p={6} w={"full"} maxW={"900px"}>
                    <Tabs>
                        <TabList>
                            <Tab>DNI</Tab>
                            <Tab>RTN</Tab>
                            <Tab>Autorización buro</Tab>
                            <Tab>Comprobante de ingreso</Tab>
                            <Tab>Licencia</Tab>
                            <Tab>Solicitud de préstamo</Tab>
                            <Tab>Recibo de servicios</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/1`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'DNI frontal' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 1)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>DNI Frontal</FormLabel>
                                                <InputFile name={"image"} label={"DNI Frontal"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/2`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'Dui Dorso' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 2)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>DNI Dorso</FormLabel>
                                                <InputFile name={"image"} label={"DNI Dorso"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/3`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'RTN' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 3)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>RTN</FormLabel>
                                                <InputFile name={"image"} label={"RTN"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/4`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'Autorización buro' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 4)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>Autorización buro</FormLabel>
                                                <InputFile name={"image"} label={"Autorización buro"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/5`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'Comprobante de ingresos' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 5)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>Comprobante de ingresos</FormLabel>
                                                <InputFile name={"image"} label={"Comprobante de ingresos"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/6`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'Licencia' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 6)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>Licencia</FormLabel>
                                                <InputFile name={"image"} label={"Licencia"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/7`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'Solicitud de préstamo' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 7)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>Solicitud de préstamo</FormLabel>
                                                <InputFile name={"image"} label={"Solicitud de préstamo"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>
                            <TabPanel>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/8`} w={'auto'} h={'200px'} onError={replaceImage} m={"auto"} />
                                <Formik
                                    initialValues={{ image: null }}
                                    validate={values => FormValidator([
                                        { field: 'image', value: values.image, rules: ['required'], lang: 'Recibo de servicios' }
                                    ])}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true)
                                        const formData = new FormData()
                                        for (let value in values) {
                                            formData.append(value, values[value])
                                        }
                                        formData.append('id', client.id)
                                        formData.append('order', 8)
                                        Promise.resolve(documentImage(formData, client.id))
                                            .then(() => {
                                                setSubmitting(false)
                                                resetForm()
                                            })
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                                        <form onSubmit={handleSubmit} encType="multipart/form-data">

                                            <FormControl isInvalid={errors.image && touched.image}>
                                                <FormLabel>Recibo de servicios</FormLabel>
                                                <InputFile name={"image"} label={"Recibo de servicios"} setFieldValue={setFieldValue} value={values.image} />
                                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                            </FormControl>

                                            <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    }
                                </Formik>
                            </TabPanel>

                        </TabPanels>
                    </Tabs>
                </Box>
            </Flex>
        </Layout >
    )
}

const mapStateToProps = (state) => ({
    client: getClient(state, getAuth(state))
})

export default connect(mapStateToProps, { documentImage })(Documents)