import React from "react"
import {
    Flex,
    Box,
    Button, Text
} from '@chakra-ui/react'
import { Link } from "react-router-dom"

const SolicitudFinish = (props) => {

    const { nombres, apellidos } = props
    return (
        <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={6} mb={6}>
            <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"} textAlign={"center"}>
                <Text fontSize={"2xl"}> <b>Felicidades {nombres} {apellidos}</b></Text>
                <Text fontSize={"2xl"}>
                    <b>¡Estás a un paso de hacer tu sueño realidad !</b>
                </Text>
                <Text>En unos momentos recibirás un email con la información de tu precalifcacion y uno de nuestros asesores te contactará en breve</Text>
                <br />

                <Link to={"/"}>
                    <Button colorScheme="easycar2" mt={4} >
                        Regresar al inicio
                    </Button>
                </Link>
            </Box>
        </Flex>
    )
}

export default SolicitudFinish