// Rules
const required = (value, field) => {
    switch (typeof value) {
        case 'string': return value !== '' ? undefined : `${field} es requerido`
        default: return value ? undefined : `${field} es requerido`
    }
}
const email = (value, field) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? undefined : `${field} debe ser un correo valido`
const mustBeNumber = (value, field) => (isNaN(value) ? `${field} debe ser un número` : undefined)
const minValue = (value, min, field) => isNaN(value) || value >= min ? undefined : `${field} debe ser más grande que ${min}`
const maxValue = (value, max, field) => isNaN(value) || value <= max ? undefined : `${field} debe ser menor que ${max}`
const alpha = (value, field) => /^[A-Za-z]+$/.test(value) === false ? `El campo ${field} debe ser alfabético.` : undefined
const alpha_num = (value, field) => /^[A-Za-z0-9]+$/.test(value) === false ? `El campo ${field} debe ser alfanúmerico.` : undefined
const alpha_dash = (value, field) => /^[A-Za-z0-9_]+$/.test(value) === false ? `El campo ${field} debe puede tener caracteres alfanúmericos o "_".` : undefined
const same = (value, param, field) => value !== param ? `El campo ${field} no coincide` : undefined

//Funciones
const composeValidators = ({ rules, value, param, field, lang }) => {
    return rules.reduce((error, validator) => {
        switch (validator) {
            case 'required': return required(value, lang || field)
            case 'email': return email(value, lang || field)
            case 'mustBeNumber': return mustBeNumber(value, lang || field)
            case 'minValue': return minValue(value, param, lang || field)
            case 'maxValue': return maxValue(value, param, lang || field)
            case 'alpha': return alpha(value, lang || field)
            case 'alpha_num': return alpha_num(value, lang || field)
            case 'alpha_dash': return alpha_dash(value, lang || field)
            case 'same': return same(value, param, lang || field)
            default: return error
        }
    }, undefined)
}

/**
 * Estructura de validación
 * [{ field: Campo, rules [validacion], value: valor, param: parametro}]
 * 
 */
export const FormValidator = (validators) => {
    //Return [{field, error}]
    return validators.reduce((errors, validation) => {
        let errorList = composeValidators(validation)
        if (errorList !== undefined)
            return { ...errors, ...{ [validation.field]: errorList } }
        return errors
    }, {})
} 