import axios from 'axios'
import {
    FETCH_CLIENT,
    FETCH_CLIENTS,
    APIpath,
    setRedirect,
    cleanCar,
    addToast,
} from '../actions'

const setClients = (clients) => ({
    type: FETCH_CLIENTS,
    clients
})

const setClient = (client) => ({
    type: FETCH_CLIENT,
    client
})

export const fetchClients = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente`)
        .then(function (response) {
            dispatch(setClients(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchClient = (id) => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente/find/${id}`)
        .then(function (response) {
            dispatch(setClients(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const saveClient = (values) => dispatch => {
    //Verificacion de cliente existente
    axios.get(`${APIpath}/cliente/verify-existing-mail/${values.email}`)
        .then(function (response) {
            if (response.data.length === 0) {
                //Si no existe guardamos el usuario
                axios.post(`${APIpath}/cliente/register`, values)
                    .then(function (response) {
                        console.log("completed")
                    })
                    .catch(function (err) {
                        console.log(err)
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
            }
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const saveSolicitud = (values) => dispatch => {
    axios.get(`${APIpath}/cliente/verify-existing-mail/${values.email}`)
        .then(function (response) {
            //Actualizamos el usuario 
            axios.post(`${APIpath}/cliente/storefront`, { ...values, id: response.data[0].id })
                .then(function (storeResponse) {
                    //Al finalizar creamos la solicitud
                    axios.post(`${APIpath}/solicitud/store`, { ...values, id: response.data[0].id })
                        .then(function (response) {
                            cleanCar()(dispatch)
                        }).catch(function (err) {
                            dispatch(addToast('error', 'El proceso ha fallado'))
                        })
                }).catch(function (err) {
                    dispatch(addToast('error', 'El proceso ha fallado'))
                })
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateClient = (values) => dispatch => {
    axios.post(`${APIpath}/cliente/update/`, values)
        .then(function (response) {
            dispatch(setClients(response.data))
            dispatch(addToast('success', 'Datos actualizados'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateClientPassword = (values, resetForm) => dispatch => {
    //Revisión de contraseña si es la antigua es correcta
    axios.post(`${APIpath}/cliente/auth`, { email: values.email, password: values.oldpassword })
        .then(function (response) {
            if (response.data.status) {
                axios.post(`${APIpath}/cliente/password`, values)
                    .then(function (response) {
                        dispatch(addToast('success', 'Contraseña actualizada'))
                        resetForm()
                    })
                    .catch(function (err) {
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
            } else {
                dispatch(addToast('error', 'Las Credenciales no se encuentran en nuestros registros'))
            }
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const activateClient = (values, id, code) => dispatch => {
    axios.post(`${APIpath}/cliente/activate/${id}/${code}`, values)
        .then(function (response) {
            dispatch(setRedirect(true))
            dispatch(addToast('success', 'Actualización realizada con éxito'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const documentImage = (values, id) => (dispatch) => {

    //Identificamos si hay registro para ese cliente
    axios.get(`${APIpath}/cliente/document/image/${id}`)
        .then(function (response) {
            //Evaluamos si hay registro
            response.data.length === 0 ?
                axios.post(`${APIpath}/cliente/document/image/store`, values)
                    .then(function (response) {
                        dispatch(addToast('success', 'Datos actualizados'))
                        window.location.reload(false);
                    })
                    .catch(function (err) {
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
                :
                axios.post(`${APIpath}/cliente/document/image/update`, values)
                    .then(function (response) {
                        dispatch(addToast('success', 'Datos actualizados'))
                        window.location.reload(false);
                    })
                    .catch(function (err) {
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export {
    setClient
}