import axios from 'axios'
import {
    FETCH_CARS,
    SELECT_CAR,
    APIpath,
    setRedirect,
    addToast
} from '../actions'

const setCars = (cars) => ({
    type: FETCH_CARS,
    cars
})

export const fetchCars = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/carro/front`)
        .then(function (response) {
            dispatch(setCars(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const filterCar = (filters) => (dispatch) => {
    axios.post(`${APIpath}/carro/filter`, filters)
        .then(function (response) {
            dispatch(setCars(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const filterBar = (searchValue) => dispatch => {
    axios.post(`${APIpath}/carro/filterbar`, { searchValue: searchValue })
        .then(function (response) {
            dispatch(setCars(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const selectCar = (id) => (dispatch) => {
    dispatch(({
        type: SELECT_CAR,
        id
    }))
}

export const cleanCar = () => (dispatch) => {
    dispatch(({
        type: SELECT_CAR,
        id: null
    }))
}