import React from "react"
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Button,
    CircularProgress,
    FormErrorMessage,
    Text
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import { Link } from 'react-router-dom'


const FormularioPrestamo = (props) => {

    const { setDataCar, prima, montoSolicitado, ingresos, gastos } = props

    return (
        <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={6} mb={6}>
            <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"}>

                <Formik
                    initialValues={{
                        prima: prima,
                        montoSolicitado: montoSolicitado,
                        ingresos: ingresos,
                        gastos: gastos,
                        terminos: false,
                        terminos2: false
                    }}
                    validate={values => FormValidator([
                        { field: 'prima', value: values.prima, rules: ['required'], lang: 'Monto de Prima' },
                        { field: 'montoSolicitado', value: values.montoSolicitado, rules: ['required'], lang: 'Cuota Deseada' },
                        { field: 'ingresos', value: values.ingresos, rules: ['required'], lang: 'Ingresos' },
                        { field: 'gastos', value: values.gastos, rules: ['required'], lang: 'Gastos' },
                        { field: 'terminos', value: values.terminos, rules: ['required'], lang: 'Terminos y condiciones' },
                        { field: 'terminos2', value: values.terminos2, rules: ['required'], lang: 'Privacidad' },
                    ])}

                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        Promise.resolve(setDataCar(values))
                            .then(setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Text>Bienvenido/a Necesitamos unos datos adicionales: </Text>
                            <FormControl isInvalid={errors.prima && touched.prima}>
                                <FormLabel>Monto de prima:</FormLabel>
                                <Input type="number" min={0} step={0.01} name='prima' onChange={handleChange} onBlur={handleBlur} value={values.prima} />
                                <FormErrorMessage>{errors.prima}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.montoSolicitado && touched.montoSolicitado}>
                                <FormLabel>Cuota Deseada:</FormLabel>
                                <Input type="number" min={0} step={0.01} name='montoSolicitado' onChange={handleChange} onBlur={handleBlur} value={values.montoSolicitado} />
                                <FormErrorMessage>{errors.montoSolicitado}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.ingresos && touched.ingresos}>
                                <FormLabel>Ingresos:</FormLabel>
                                <Input type={'number'} size="lg" name={"ingresos"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.ingresos} />
                                <FormErrorMessage>{errors.ingresos}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.gastos && touched.gastos}>
                                <FormLabel>Gastos:</FormLabel>
                                <Input type={'number'} size="lg" name={"gastos"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.gastos} />
                                <FormErrorMessage>{errors.gastos}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt={"6"} isInvalid={errors.terminos && touched.terminos}>
                                <Checkbox size="lg" name={"terminos"} onChange={handleChange} onBlur={handleBlur} value={values.terminos} >
                                    Acepta los <Link to={"/terminos-condiciones"} target="_blank" rel="noopener noreferrer">términos y condiciones</Link> y <Link to={"/aviso-privacidad"} target="_blank" rel="noopener noreferrer">aviso de privacidad</Link>
                                </Checkbox>
                                <FormErrorMessage>{errors.terminos}</FormErrorMessage>
                            </FormControl>
                            <Text><b>Nota: los datos son compartidos única y exclusivamente con Easycar </b></Text>
                            <FormControl mt={"6"} isInvalid={errors.terminos2 && touched.terminos2}>
                                <Checkbox size="lg" name={"terminos2"} onChange={handleChange} onBlur={handleBlur} value={values.terminos2} >
                                    Acepto compartir mi información privada para este proceso de presolicitud que estoy iniciando con Easycar
                                </Checkbox>
                                <FormErrorMessage>{errors.terminos2}</FormErrorMessage>
                            </FormControl>
                            <Button w={"full"}  colorScheme="easycar2" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Enviar solicitud'}
                            </Button>
                        </form>
                    )}
                </Formik>

            </Box>
        </Flex>
    )
}

export default FormularioPrestamo