const entities = (state = {
    departamento: [],
    municipio: [],
    roles: [],
    "anno-vehiculo":[],
    modelo: [],
    marca: [],
    clase: [],
    profesion: [],
    combustible: [],
    transmision: [],
    direccion: [],
    color: [],
    zona: []
}, action) => {

    switch (action.type) {
        case 'FETCH_ENTITIES': {
            return { ...state, [action.name]: action.entity }
        }
        default: return state
    }
}

export const getEntity = (state, name) => state[name]

export default entities