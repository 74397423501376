import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom"
import { activateClient, fetchClient } from '../actions'
import { getRedirect, getAuth, getClient } from '../reducers'
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    Stack,
    Box,
    Text
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'

class RegisterActivate extends React.Component {

    componentDidMount() {
        this.props.fetchClient(this.props.match.params.id)
    }

    render() {

        const { location, isAuth, activateClient, redirect, cliente } = this.props

        if (isAuth) {
            let { from } = location.state || { from: { pathname: '/status' } }
            return <Redirect to={from} />
        }

        return <Layout>
            {redirect && <Redirect to="/status" />}
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8}>

                    <Text>
                        Gracias por registrarte {cliente.cNombres} {cliente.cApellidoPaterno} completa tu cambio de clave para finalizar
                    </Text>
                    <br />
                    <Formik
                        initialValues={{ password: '', repeatpassword: ''}}
                        validate={values => FormValidator([
                            { field: 'password', value: values.password, rules: ['required'] },
                            { field: 'repeatpassword', value: values.repeatpassword, rules: ['required', 'same'], param: values.password, lang: 'repetir contraseña' },
                        ])}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            Promise.resolve(activateClient(values, this.props.match.params.id, this.props.match.params.code))
                                .then(setSubmitting(false))
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <FormControl isInvalid={errors.password && touched.password}>
                                    <FormLabel>Contraseña</FormLabel>
                                    <Input type={'password'} size="lg" name={"password"} onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={errors.repeatpassword && touched.repeatpassword}>
                                    <FormLabel>Repetir Contraseña</FormLabel>
                                    <Input type={'password'} size="lg" name={"repeatpassword"} onChange={handleChange} onBlur={handleBlur} value={values.repeatpassword} />
                                    <FormErrorMessage>{errors.repeatpassword}</FormErrorMessage>
                                </FormControl>
                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress /> : 'Finalizar!'}
                                </Button>
                            </form>
                        )}
                    </Formik>

                </Box>

                <br />
                <br />
                <br />

                <br />
            </Stack>
        </Layout >
    }
}

const mapStateToProps = (state, ownProps) => ({
    redirect: getRedirect(state),
    isAuth: getAuth(state) === undefined ? false : true,
    cliente: getClient(state, ownProps.match.params.id)
})

const mapDispatchToProps = ({ activateClient, fetchClient })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterActivate))