import {
    CONFIGURATION,
    LOADING,
    REDIRECT,
    APIpath,
    ADD_TOAST,
    REMOVE_TOAST,
    RELOAD
} from '../actions'
const axios = require('axios')

const setConfiguration = (config) => ({
    type: CONFIGURATION,
    config
})

export const setLoading = (status) => ({
    type: LOADING,
    status
})

export const setRedirect = (status) => ({
    type: REDIRECT,
    status
})

export const addToast = (status, message, id = "default") => ({
    type: ADD_TOAST,
    status, message, id
})

export const removeToast = () => ({
    type: REMOVE_TOAST
})

export const reloadImage = () => ({
    type: RELOAD
})

export const fetchConfiguration = () => (dispatch) => {
    axios.get(`${APIpath}/configuration`)
        .then(function (response) {
            dispatch(setConfiguration(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateRedirect = (redirect) => (dispatch) => {
    dispatch(setRedirect(redirect))
}