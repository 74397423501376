import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Flex,
    Image,
    useColorModeValue,
} from '@chakra-ui/react'
import yt from '../multimedia/icons/yt.png'
import ig from '../multimedia/icons/ig.png'
import tt from '../multimedia/icons/tt.png'
import fb from '../multimedia/icons/fb.png'
import Logo from '../multimedia/logo2.png'
import SocialButton from './SocialButton'
import { Link } from 'react-router-dom'


export default function Footer(props) {

    const { config } = props
    return (
        <Box
            bg={"black"}
            color={useColorModeValue('gray.200', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
                    <Stack align={'flex-start'}>
                        <Link to={'/cars'} onClick={() => window.scrollTo(0, 0)}>Compra tu vehículo</Link>
                        <Link to={'/requisitos'} onClick={() => window.scrollTo(0, 0)}>Paga a cuotas</Link>
                        <Link to={'/vende'} onClick={() => window.scrollTo(0, 0)}>Vende tu vehículo</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <Link to={'/agencias'} onClick={() => window.scrollTo(0, 0)}>Sedes</Link>
                        <Link to={'/faq'} onClick={() => window.scrollTo(0, 0)}>Preguntas frecuentes</Link>
                        <Link to={"/experiencia-easycar"} onClick={() => window.scrollTo(0, 0)}>Testimoniales</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <Link to={'/contacto'} onClick={() => window.scrollTo(0, 0)}>Contáctanos</Link>
                    </Stack>
                </SimpleGrid>
            </Container>
            <Box py={10}>
                <Flex
                    align={'center'}
                    _before={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        mr: 8,
                    }}
                    _after={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        ml: 8,
                    }}>
                    <Image src={Logo} />
                </Flex>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ base: 'center', md: 'space-between' }}
                    align={{ base: 'center', md: 'center' }}>
                    <Text>Copyright © 2023 EASYCAR. todos los derechos reservados  <Link to={"/aviso-privacidad"}>Aviso de Privacidad</Link> |  <Link to={"/terminos-condiciones"}>Términos y condiciones</Link>
                        <br />{ config.ubicacion }
                    </Text>
                    <Stack direction={'row'} spacing={6}>
                        <SocialButton src={yt} alt={'YouTube'} href={config.youtube || '#'} />
                        <SocialButton src={ig} alt={'Instagram'} href={config.instagram || '#'} />
                        <SocialButton src={fb} alt={'Facebook'} href={config.facebook || '#'} />
                        <SocialButton src={tt} alt={'TikTok'} href={config.tiktok || '#'} />
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}