import React from 'react'
import { Flex, Button, Box } from '@chakra-ui/react'


const CanalPrecalificacion = (props) => {

    const { setStep } = props

    return (
        <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={6} mb={6}>
            <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"}>
                ¿Cuál canal deseas para tu precalificación?
                <a
                    href={`https://wa.me/+50498493492?text=Hola,%20estoy%20interesado%20en%20comprar%20un%20vehículo.`}
                    target={"_blank"}
                    rel="noreferrer">
                    <Button w={"full"} colorScheme="easycar2" mt={4} >
                        Chatea con un asesor
                    </Button>
                </a>
                <br /><br />
                <Button w={"full"} colorScheme="easycar2" onClick={setStep}>
                    Precalifica en línea
                </Button>
            </Box>
        </Flex>
    )
}

export default CanalPrecalificacion