import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'

const NavItem = ({ icon, children, path, type, ...rest }) => {
    return (
            <Text as='b' color={type === "green" ? 'green.500' : type === "white" ? 'white' : 'black'}>
                <Flex align="center" p="2" mx="2" borderRadius={"none"} role="group" cursor="pointer" _hover={{ bg: 'green.600', color: 'white' }} {...rest}>
                    {icon && (<Icon mr="4" fontSize="16" _groupHover={{ color: 'white' }} as={icon} />)}
                    {children}
                </Flex>
            </Text>
    )
}

export default NavItem