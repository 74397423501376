import React from "react"
import { connect } from 'react-redux'
import {
    getClient,
    getAuth,
    getCar,
    getCarSelected
} from '../reducers'
import { saveClient, saveSolicitud } from '../actions'
import Layout from '../layout'
import FormularioInicial from "../containers/FormularioInicial"
import CanalPrecalificacion from "../containers/CanalPrecalificacion"
import FormularioDatosPersonales from "../containers/FormularioDatosPersonales"
import FormularioPrestamo from "../containers/FormularioPrestamo"
import SolicitudFinish from "../containers/SolicitudFinish"
import { Spinner, Box, Image, Flex } from '@chakra-ui/react'
import leasing from '../multimedia/imagenes/leasing.jpg'
import leasing2 from '../multimedia/imagenes/leasing2.jpg'

class Leasing extends React.Component {

    state = {
        nombres: '',
        apellidos: '',
        telefono: '',
        email: '',
        documento: '',
        genero: '',
        zona_residencial: '',
        departamento: '',
        municipio: '',
        prima: 0,
        montoSolicitado: 0,
        ingresos: 0,
        gastos: 0,
        car: 0,
        terminos: false,
        tipo: 'Compra a cuotas',
        step: -1
    }

    componentDidMount() {
        const { client, car } = this.props

        if (client) {
            this.setState({
                nombres: client.cNombres,
                nombres2: client.cNombres2,
                apellidos: client.cApellidoPaterno,
                apellidos2: client.cApellidoMaterno,
                telefono: client.cTelefono,
                email: client.cEmail,
                genero: client.cGenero,
                documento: client.cDocumento,
                fecha_nacimiento: client.dFechaNacimiento ? new Date(client.dFechaNacimiento).toISOString().split('T')[0] : "",
                zona_residencial: client.cZonaResidencial,
                departamento: client.cDepartamento,
                municipio: client.cMunicipio,
                step: 0
            }, () => {
                if (car) this.setState({ car: car.id })
            })
        } else {
            if (car) this.setState({ car: car.id, step: 0 })
            else this.setState({ step: 0 })
        }
    }

    setDataStart = (values) => {

        this.setState({ ...values, step: 1 }, () => {
            this.props.saveClient(this.state)
        })
    }

    setStep = () => {
        this.setState({ step: 2 })
    }

    setDataClient = (values) => {
        this.setState({ ...values, step: 3 })
    }

    setDataCar = (values) => {
        this.setState({ ...values, step: 4 }, () => {
            this.props.saveSolicitud(this.state)
        })
    }


    render() {

        const { car } = this.props
        switch (this.state.step) {
            case 0:
                return <Layout>
                    <Flex maxW={"1400px"} justifyContent={"center"} padding={"10px"} margin={"auto"} marginTop={"20px"} marginBottom={"20px"} flexDir={{ base: 'column', md: 'row' }}>
                        <Box flex={1} order={{ base: 2, md: 1 }} width={{ base: 'full', md: '50%' }}>
                            <FormularioInicial
                                setDataStart={this.setDataStart}
                                telefono={this.state.telefono}
                                email={this.state.email}
                                isAuth={this.props.isAuth}
                            />
                        </Box>
                        <Box order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"} width={{ base: 'full', md: '50%' }}>
                            <Image src={leasing} />
                        </Box>
                    </Flex>
                </Layout>
            case 1:
                return <Layout>
                    <Flex maxW={"1400px"} justifyContent={"center"} padding={"10px"} margin={"auto"} marginTop={"20px"} marginBottom={"20px"} flexDir={{ base: 'column', md: 'row' }}>
                        <Box flex={1} order={{ base: 2, md: 1 }} width={{ base: 'full', md: '50%' }}>
                            <CanalPrecalificacion setStep={this.setStep} />
                        </Box>
                        <Box order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"} width={{ base: 'full', md: '50%' }}>
                            <Image src={leasing} />
                        </Box>
                    </Flex>
                </Layout>
            case 2:
                return <Layout>
                    <Flex maxW={"1400px"} justifyContent={"center"} padding={"10px"} margin={"auto"} marginTop={"20px"} marginBottom={"20px"} flexDir={{ base: 'column', md: 'row' }}>
                        <Box flex={1} order={{ base: 2, md: 1 }} width={{ base: 'full', md: '50%' }}>
                            <FormularioDatosPersonales
                                setDataClient={this.setDataClient}
                                nombres={this.state.nombres}
                                nombres2={this.state.nombres2}
                                apellidos={this.state.apellidos}
                                apellidos2={this.state.apellidos2}
                                documento={this.state.documento}
                                fecha_nacimiento={this.state.fecha_nacimiento}
                                genero={this.state.genero}
                                zona_residencial={this.state.zona_residencial}
                                cDepartamento={this.state.departamento}
                                cMunicipio={this.state.municipio}
                            />
                        </Box>
                        <Box order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"} width={{ base: 'full', md: '50%' }}>
                            <Image src={leasing} />
                        </Box>
                    </Flex>
                </Layout>
            case 3:
                return <Layout>
                    <Flex maxW={"1400px"} justifyContent={"center"} padding={"10px"} margin={"auto"} marginTop={"20px"} marginBottom={"20px"} flexDir={{ base: 'column', md: 'row' }}>
                        <Box flex={1} order={{ base: 2, md: 1 }} width={{ base: 'full', md: '50%' }}>
                            <FormularioPrestamo
                                car={car}
                                setDataCar={this.setDataCar}
                                prima={this.state.prima}
                                montoSolicitado={this.state.montoSolicitado}
                                ingresos={this.state.ingresos}
                                gastos={this.state.gastos}
                            />
                        </Box>
                        <Box order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"} width={{ base: 'full', md: '50%' }}>
                            <Image src={leasing} />
                        </Box>
                    </Flex>
                </Layout>
            case 4:
                return <Layout>
                    <Flex maxW={"1400px"} justifyContent={"center"} padding={"10px"} margin={"auto"} marginTop={"20px"} marginBottom={"20px"} flexDir={{ base: 'column', md: 'row' }}>
                        <Box flex={1} order={{ base: 2, md: 1 }} width={{ base: 'full', md: '50%' }}>
                            <SolicitudFinish
                                nombres={this.state.nombres}
                                apellidos={this.state.apellidos}
                            />
                        </Box>
                        <Box order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"} width={{ base: 'full', md: '50%' }}>
                            <Image src={leasing2} />
                        </Box>
                    </Flex>
                </Layout>
            default:
                return <Layout >
                    <Box textAlign={"center"} mt={"6"} mb={"6"} >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Box>
                </Layout>
        }
    }

}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state),
    client: getClient(state, getAuth(state)),
    car: getCar(state, getCarSelected(state))
})

const mapDispatchToProps = ({ saveClient, saveSolicitud })

export default connect(mapStateToProps, mapDispatchToProps)(Leasing)