import { Flex, Text, Heading, Box } from '@chakra-ui/react'

const SideCar = (props) => {

    const { icon, title, content } = props
    return (
        <Flex direction={"column"} alignItems={"center"}  width={"100%"} maxW={"300px"} height={"100%"} minHeight={"250px"} maxHeight={"400px"}>
            <Box minHeight={"100px"}>
                {icon}
            </Box>
            <Heading as={'h5'} size='md'>{title} </Heading>
            <Text>{content}</Text>
        </Flex>
    )
}

export default SideCar