import React from "react"
import {
    Box, Flex, Image, Text, Heading, UnorderedList, ListItem,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from '@chakra-ui/react'
import image1 from '../multimedia/imagenes/faq.jpg'
import Layout from '../layout'


const Faq = (props) => {

    return (
        <Layout>
            <Flex maxW={"1400px"} margin={"auto"} bg={"white"} flexDir={{ base: 'column', md: 'row' }}>
                <Box order={{ base: 2, md: 1 }} className={'sideLeft'} w={"full"} maxW={{ base: "full", md: "40%" }} pl={"5%"} m={"auto"} >
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>¿En qué podemos ayudarte?</Heading>
                    </Box>
                </Box>
                <Box order={{ base: 1, md: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", md: "60%" }}>
                    <Image alt={'Conduciendo'} objectFit='cover' src={image1} />
                </Box>
            </Flex>

            <Box w={"full"}  >
                <Flex maxW={"900px"} flexDir={"column"} p={4} m={"auto"} mt={"3.5rem"} mb={"2rem"}>

                    <Heading textAlign={"center"}>Preguntas Frecuentes</Heading>
                    <Accordion mt={"20px"}>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuál es la tasa de financiamiento?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Tasa desde el 3% según el segmento.</Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuál es el máximo de tiempo de financiamiento?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>El plazo máximo es de 48 meses y de acuerdo con el monto solicitado se le podría extender hasta 60 meses con una aprobación especial. </Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Dónde están ubicados?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Contamos con 3 agencias a nivel nacional en las siguientes ciudades:
                                <UnorderedList>
                                    <ListItem>Tegucigalpa, Col. Palmira, avenida república de Chile, contiguo a CADERH </ListItem>
                                    <ListItem>Choluteca, barrio el Hospital, plaza futura contiguo a farmacia Kielsa.</ListItem>
                                    <ListItem>Comayagua, Barrio Torondón, plaza Roble, media cuadra al este de la ferretería el Jordán, calle al mall premier.</ListItem>
                                </UnorderedList>
                            </Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Tienen algún inventario de vehículos disponibles?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>Si contamos con inventario de vehículos, nuestros asesores están sumamente capacitados para ayudarle a adquirir el auto de sus sueños. </Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿A partir de qué año aceptan financiar un vehículo?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>
                                <UnorderedList>
                                    <ListItem>Desde el 2003 hasta 2007 podemos financiar hasta un 50% en marcas comerciales</ListItem>
                                    <ListItem>Del 2008 en adelante hasta un 75%</ListItem>
                                </UnorderedList>
                            </Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Cuáles son los requisitos?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text><UnorderedList>
                                <ListItem>Identidad</ListItem>
                                <ListItem>RTN</ListItem>
                                <ListItem>Recibo público</ListItem>
                                <ListItem>Comprobante de ingresos</ListItem>
                                <ListItem>Monto de prima</ListItem>
                                <ListItem>Papeles originales del vehículo</ListItem>
                            </UnorderedList></Text></AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Text textAlign={"left"} as={"b"}>¿Qué debo hacer para adquirir el financiamiento de un vehículo?</Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}><Text>En primera instancia tendría que someterse a una pre calificación para verificar si aprueba al monto solicitado, de lo contrario se le informará a cuanto aplica de acuerdo a su capacidad de pago.</Text></AccordionPanel>
                        </AccordionItem>

                    </Accordion>

                </Flex>
            </Box>

        </Layout >
    )
}

export default Faq