import React from "react"
import { connect } from 'react-redux'
import { fetchSolicitudes } from "../actions/solicitud"
import {
    getClient,
    getAuth,
    getSolicitudes
} from '../reducers'
import Layout from '../layout'
import {
    Flex,
    Box,
    Button,
    Table, Tr, Td, Th, Thead, Tbody, Card, CardBody
} from '@chakra-ui/react'
import { Link } from "react-router-dom"

class FinanciamientoStatus extends React.Component {

    componentDidMount() {
        this.props.fetchSolicitudes(this.props.client.id)
    }

    render() {
        const { solicitudes } = this.props

        return (
            <Layout>
                <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} w={'100%'} mb={"2rem"}>
                    <Box bg={"easycar1.500"} p={3} mb={3} w={"full"}>
                        <Flex maxW={"1400px"} justifyContent={"center"} m={"auto"}>
                        </Flex>
                    </Box>

                    <Flex flexDir={{ base: 'column', md: 'row' }} w={"full"} justifyContent={"space-between"} maxW={"1400px"}>
                        <Card display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={4} className='miniCarIcon' textAlign={"center"}>
                            <Link to={"/status"}>
                                <CardBody>
                                    Panel de solicitudes
                                </CardBody>
                            </Link>
                        </Card>
                        <Card display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={4} className='miniCarIcon' textAlign={"center"}>
                            <Link to={"/profile"}>
                                <CardBody>
                                    Mi Perfil
                                </CardBody>
                            </Link>
                        </Card>
                        <Card  display={"flex"} justifyContent={"center"} variant={"elevated"} shadow={"lg"} p={4} className='miniCarIcon' textAlign={"center"}>
                            <Link to={"/profile/documents"}>
                                <CardBody>
                                    Mis Documentos
                                </CardBody>
                            </Link>
                        </Card>
                    </Flex>

                    <Box m={3} p={6} w={"full"} maxW={"1400px"} >
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Fecha</Th>
                                    <Th>Estado</Th>
                                    <Th>Ver detalles</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {solicitudes.map(solicitud =>
                                    <Tr key={solicitud.id} >
                                        <Td>{new Date(solicitud.dFechaCreacion).toISOString().split('T')[0]}</Td>
                                        <Td>{solicitud.cEtapa || 'En proceso'}</Td>
                                        <Td>
                                            <Link to={`/status/${solicitud.id}`}>
                                                <Button colorScheme={"green"}>Ver</Button>
                                            </Link>
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </Box>


                </Flex>
                <br />
                <br />
                <br />
                <br />
                <br />
            </Layout>
        )
    }

}

const mapStateToProps = (state) => ({
    client: getClient(state, getAuth(state)),
    solicitudes: getSolicitudes(state)
})

const mapDispatchToProps = ({ fetchSolicitudes })

export default connect(mapStateToProps, mapDispatchToProps)(FinanciamientoStatus)