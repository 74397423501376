import React from "react"
import { Box, Button, Flex, Image, Text, Heading } from '@chakra-ui/react'
import image1 from '../multimedia/imagenes/call3.jpg'
import Layout from '../layout'
import { Link } from 'react-router-dom'


const Contact = (props) => {

    return (
        <Layout>

            <Box w={"full"} >
                <Flex maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"} mt={"7.5rem"} mb={"2rem"}>
                    <Box flex={1} order={{ base: 2, md: 1 }} p={"10"} margin={"auto"} >
                        <Heading>¿Necesitas ayuda?¡Contáctanos!</Heading>
                        <br/>
                        <Text>Contestamos todas tus dudas de manera clara, rápida y directa. En Honduras, llámanos al (+504) 9849-3492</Text>
                        <br />
                        <Flex justifyContent={"space-around"}>
                            <a
                                href={`https://wa.me/+50498493492?text=Hola,%20estoy%20interesado%20en%20comprar%20un%20vehículo.`}
                                target={"_blank"}
                                rel="noreferrer">
                                <Button colorScheme="easycar2">Chatea con un asesor</Button>
                            </a>
                            <Link to={"faq"}>
                                <Button colorScheme="easycar2">Preguntas frecuentes</Button>
                            </Link>
                        </Flex>
                    </Box>
                    <Box flex={1} order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"}>
                        <Image alt={'call3'} height={"auto"} width={{ base: '100%', md: "100%" }} src={image1} />
                    </Box>
                </Flex>
            </Box>

        </Layout >
    )
}

export default Contact