import React from "react"
import { connect } from 'react-redux'
import Layout from '../layout'
import { fetchActiveTestimonials } from '../actions'
import { getTestimonials } from '../reducers'
import { Box, SimpleGrid, Image, Text, Heading } from '@chakra-ui/react'
import placeholder from '../multimedia/placeholder.jpg'
import { APIpath } from '../actions'

const replaceImage = (error) => {
    error.target.src = placeholder;
}

class Experiencia extends React.Component {

    componentDidMount() {
        this.props.fetchActiveTestimonials()
    }

    render() {
        const { testimonials } = this.props
        return (
            <Layout>
                <Box maxW={"1400px"} padding={"10px"} m={"auto"} mt={"2.5rem"} mb={"2rem"}>
                    <Box bg={"easycar1.500"} w={"100%"} p={3} textAlign={"center"} color={"white"} m={"auto"}>
                        <Heading>¡Tú también puedes lograrlo!</Heading>
                        <Text as={"b"} fontSize={"3xl"} >Tus sueños vehiculares son nuestro compromiso.</Text>
                    </Box>
                    <SimpleGrid columns={3} spacing={"10"} p={"16px"}>
                        {testimonials.map(testimonial =>
                            <Box
                                bg={'white'}
                                maxW="600px"
                                maxH={"600px"}
                                height={"full"}
                                borderWidth="1px"
                                rounded="lg"
                                shadow="lg"
                                position="relative">
                                <Image alt={`Preview ${testimonial.id}`} src={`${APIpath}/testimonial/image/${testimonial.id}`} fit={'cover'} align={'center'} w={'100%'} h={'400px'} onError={replaceImage} />
                            </Box>
                        )}
                    </SimpleGrid>
                </Box>
            </Layout>
        )
    }
}


const mapStateToProps = (state) => ({
    testimonials: getTestimonials(state)
})

const mapDispatchToProps = { fetchActiveTestimonials }

export default connect(mapStateToProps, mapDispatchToProps)(Experiencia)