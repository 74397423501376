import React from "react"
import { getCar, getCars } from '../reducers'
import { selectCar } from "../actions"
import { connect } from 'react-redux'
import {
    Box, Icon,
    Container,
    Card, CardHeader, CardBody, Stack, StackDivider,
    Text,
    Flex,
    Button,
    Heading,
    SimpleGrid,
    Image,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    List, ListItem, ListIcon
} from '@chakra-ui/react'
import Layout from '../layout'
import { Link } from "react-router-dom"
import CarPreview from "../components/CarPreview"
import asesor from '../multimedia/imagenes/asesor.jpg'
import CarouselMulti from 'react-multi-carousel'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import CarCard from "../components/CarCard"


const responsive = {
    largeDesktop: {
        breakpoint: { max: 3000, min: 1400 },
        items: 3,
        partialVisibilityGutter: 30
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30
    }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", right: "0", height: "100%"
    }}>
        <Icon as={ChevronRightIcon} color={"white"} boxSize={8} />
    </Button>
}

const CustomLeftArrow = ({ onClick, ...rest }) => {
    return <Button onClick={() => onClick()} style={{
        position: "absolute", outline: 0, transition: "all .5s", zIndex: 1000, opacity: 1, cursor: "pointer", border: 0, background: "rgba(0,0,0,0.7)", left: "0", height: "100%"
    }}>
        <Icon as={ChevronLeftIcon} color={"white"} boxSize={8} />
    </Button>
}

const CarDetail = (props) => {


    const { car, cars, selectCar } = props

    const pickCar = () => {
        selectCar(car.id)
    }

    let i = 0

    return (
        <Layout>
            <Container maxW={'7xl'}>

                <Flex maxW={"1400px"} flexDir={{ base: 'column', lg: 'row' }} p={4} m={"auto"} marginTop={"20px"} marginBottom={"7rem"}>
                    <CarPreview id={car.id} />

                    <Card marginTop={{ base: "4rem", md: 0 }}>
                        <CardHeader>
                            <Heading>{car.cMarca} {car.cModelo} {car.nAño} </Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Text>{car.nRecorrido.toLocaleString('en-US')} {car.cRecorridoTipo} - {car.cLugar}</Text>
                                <Text>Contado L{car.nValor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
                                <Text>Desde L{car.nCuota.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2})}/mes </Text>
                                <Link to={`/cotizar`} onClick={pickCar}>
                                    <Button colorScheme="easycar1" variant='outline' w={'full'} size={'lg'} py={'7'} _hover={{ transform: 'translateY(2px)', boxShadow: 'lg', }} >Llévatelo a cuotas</Button>
                                </Link>
                                <Text>Año {car.nAño} </Text>
                                <Text>Versión {car.cModelo}</Text>
                                <Text>Transmisión {car.cTransmision}</Text>
                                <a
                                    href={`https://wa.me/+50498493492?text=Hola,%20estoy%20interesado%20en%20comprar%20un%20vehículo.`}
                                    target={"_blank"}
                                    rel="noreferrer">
                                    <Button colorScheme="easycar1" w={'full'} size={'lg'} py={'7'} _hover={{ transform: 'translateY(2px)', boxShadow: 'lg', }}>Llévatelo a contado</Button>
                                </a>
                            </Stack>
                        </CardBody>
                    </Card>
                </Flex>

                <Box textAlign={"center"} w={"full"} mb={"10px"} marginTop={"20px"}>
                    <Text as={"b"} fontSize={"3xl"} textTransform={'uppercase'} >Características</Text>
                </Box>
                <SimpleGrid
                    columns={{ sm: 2, md: 3 }}
                    marginTop={"20px"} marginBottom={"20px"}
                    padding={6}
                    spacing={10}
                >
                    <Box>
                        <Text as={"b"}>Marca</Text>
                        <hr />
                        <Text> {car.cMarca} </Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Modelo</Text>
                        <hr />
                        <Text> {car.cModelo}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Año</Text>
                        <hr />
                        <Text> {car.nAño}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Color</Text>
                        <hr />
                        <Text>{car.cColor}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Recorrido</Text>
                        <hr />
                        <Text> {car.nRecorrido.toLocaleString('en-US')} {car.cRecorridoTipo}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Cilindraje</Text>
                        <hr />
                        <Text>{car.cMotor}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Tracción</Text>
                        <hr />
                        <Text> {car.cTraccion}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Transmisión</Text>
                        <hr />
                        <Text> {car.cTransmision}</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Versión</Text>
                        <hr />
                        <Text> {car.cExtras} </Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Pasajeros</Text>
                        <hr />
                        <Text>{car.nPasajeros} Personas</Text>
                    </Box>
                    <Box>
                        <Text as={"b"}>Combustible</Text>
                        <hr />
                        <Text>{car.cCombustible}</Text>
                    </Box>
                </SimpleGrid>

                <Box w={"full"} >
                    <Flex bg={"easycar1.500"} maxW={"1400px"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"} color={"white"} marginTop={"20px"} marginBottom={"20px"}>
                        <Box width={{ base: 'full', md: "50%" }} padding={4}>
                            <Heading>Paga tu vehículo hasta en 60 meses</Heading>
                            <br />
                            <Text>Obtén tu plan de pago en estos momentos.</Text>
                            <Text>Sube toda la información requerida para brindarte la oferta.</Text>
                            <br />
                            <Link to={`/cotizar`} onClick={pickCar}>
                                <Button colorScheme="easycar2">Obtén tu plan de pago en línea</Button>
                            </Link>
                        </Box>
                        <Box width={{ base: 'full', md: "50%" }}>
                            <Image src={asesor} width={"full"} rounded={6} maxH={"350px"} objectFit='cover' />
                        </Box>
                    </Flex>
                </Box>

                <Box textAlign={"center"} w={"full"} mb={"10px"} marginTop={"20px"}>
                    <Text as={"b"} fontSize={"3xl"} >Autos similares</Text>
                </Box>

                <CarouselMulti showDots={false} infinite={false} responsive={responsive} partialVisible={true} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} >
                    {cars.filter(carSimilar => {
                        if (carSimilar.id === car.id) return false
                        if(i === 0) return true
                        else {
                            if (Math.random() < 0.5) return false
                            else return true
                        }
                    }).map(carSimilar => {
                        if (i <= 4) {
                            i++
                            return <CarCard car={carSimilar} key={carSimilar.id} pickCar={pickCar} />
                        }
                        return undefined
                    }
                    )}
                </CarouselMulti>

                <Box textAlign={"center"} w={"full"} mb={"10px"} marginTop={"30px"}>
                    <Text as={"b"} fontSize={"3xl"} >Requisitos mínimos para que obtengas el vehículo de tus sueños</Text>
                </Box>

                <Tabs variant='soft-rounded' colorScheme='easycar1' padding={"10px"} mt={"7.5rem"} mb={"2rem"}>
                    <TabList justifyContent={"center"}>
                        <Tab>A cuotas</Tab>
                        <Tab>De Contado</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel fontSize={"xl"}>
                            <List>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />DNI</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />RTN</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Recibo público</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Comprobante de ingresos</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Documentación original del vehículo</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Contar con un monto de prima</ListItem>
                            </List>
                        </TabPanel>
                        <TabPanel fontSize={"xl"}>
                            <List>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />DNI</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />RTN</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Carnet de Residente (en caso de residentes) </ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Escritura de constitución, Permiso de Operación (En caso de personas Jurídicas)</ListItem>
                            </List>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </Layout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    car: getCar(state, ownProps.match.params.id),
    cars: getCars(state)
})

const mapDispatchToProps = { selectCar }

export default connect(mapStateToProps, mapDispatchToProps)(CarDetail)