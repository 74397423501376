const car= (state = [], action) => {

    switch(action.type){
        case 'FETCH_CAR': 
            state.push(action.car)
            return state
        case 'FETCH_CARS': return action.cars 
        case 'LOG_OUT': return []
        default: return state
    }
}

export const getCars = (state) => state
export const getCar = (state, id) => state.reduce((p, car) => {
    if (Number(car.id) === Number(id)) return car
    return p
}, null)


export default car