import React from "react"
import { fetchCars, fetchEntity, fetchFilter,  fetchEntityWithId, fetchFilterWithId, filterCar, filterBar, selectCar } from '../actions'
import { getCars, getEntity } from '../reducers'
import { connect } from 'react-redux'
import {
    SimpleGrid, Box, Button, Flex, Text, Image, InputGroup, Input, InputRightElement
} from '@chakra-ui/react'
import FiltradoCarros from "../containers/Filtrado"
import CarCard from "../components/CarCard"
import Layout from '../layout'
import money from '../multimedia/icons/icono0.svg'
import { SearchIcon } from '@chakra-ui/icons'
import empty from '../multimedia/icons/Towing-pana.svg'
import { Link } from "react-router-dom"

class Cars extends React.Component {

    state = {
        lugar: '',
        tipo: '',
        marca: '',
        modelo: '',
        anno: '',
        precio: 0,
        color: '',
        transmision: '',
        pasajero: 0,
        kilometraje: 0,
        combustible: '',
        searchbar: ''
    }

    componentDidMount() {
        this.props.fetchCars()
        this.props.fetchFilter('anno-vehiculo')
        this.props.fetchFilter('clase')
        this.props.fetchFilter('marca')
        this.props.fetchFilter('color')
        this.props.fetchFilter('combustible')
        this.props.fetchFilter('transmision')
        this.props.fetchFilter('zona')
    }

    applyFilter = (e) => {
        this.setState({ [e.target.name]: e.target.value },
            () => this.props.filterCar(this.state))
    }

    priceFilter = (value) => {
        this.setState({ precio: value },
            () => this.props.filterCar(this.state))
    }

    pasajeroFilter = (value) => {
        this.setState({ pasajero: value },
            () => this.props.filterCar(this.state))
    }

    kilometrajeFilter = (value) => {
        this.setState({ kilometraje: value },
            () => this.props.filterCar(this.state))
    }

    marcaFilter = (e) => {
        this.setState({ [e.target.name]: e.target.value },
            () => {
                this.props.filterCar(this.state)
                if (e.target.value !== "") this.props.fetchFilterWithId('modelo', e.target.value)
            })
    }

    clearFliter = () => {
        this.setState({
            lugar: '',
            tipo: '',
            marca: '',
            modelo: '',
            anno: '',
            precio: 0,
            color: '',
            transmision: '',
            pasajero: 0,
            kilometraje: 0,
            combustible: '',
            searchbar: ''
        },
            () => this.props.filterCar(this.state))
    }

    pickCar = (id) => {
        this.props.selectCar(id)
    }

    applyFilterBar = (e) => {
        this.setState({ searchbar: e.target.value }, () => {

            if (this.state.searchbar.length >= 3) {
                this.props.filterBar(this.state.searchbar)
            }
        })

    }

    render() {

        const { cars, clases, marcas, modelos, annos, combustibles, transmisiones, colores, zonas } = this.props

        return (
            <Layout>
                <Box bg={"easycar1.500"} p={3} mb={3}>
                    <Flex maxW={"1400px"} justifyContent={"center"} m={"auto"}>
                        <InputGroup>
                            <Input placeholder='Buscar por marca, modelo, año, color' bg={"white"} size='lg' value={this.state.searchbar} onChange={this.applyFilterBar} />
                            <InputRightElement children={<SearchIcon color={"GrayText"} />} />
                        </InputGroup>
                    </Flex>
                </Box>
                <Flex maxW={"1400px"} justifyContent={"center"} m={"auto"} flexDir={{ base: 'column', lg: 'row' }} >
                    <Box width={{ base: 'full', lg: '25%' }} >
                        <Flex justifyContent={"space-around"} marginBottom={"5px"}>
                            <Button size={"sm"} variant='outline' colorScheme={"green"} onClick={this.clearFliter} >Limpiar filtros</Button>
                            <Text fontSize={"small"}>Resultados: {cars.length}</Text>
                        </Flex>
                        <Link to={"/requisitos"}>
                            <Flex justifyContent={"space-around"} alignItems={"center"} bg={"green.100"} border={"2px"} borderColor={"green.500"} rounded={5} m={"auto"} mt={"10px"} p={"3px"} >
                                <Image src={money} height={"40px"} />
                                <Box width={"80%"}>
                                    <Text as={"b"} fontSize={"xl"} >Compra tu auto a meses</Text>
                                    <Text >Ingresa tus datos y simula un plan de pagos al instante</Text>
                                </Box>
                            </Flex>
                        </Link>
                        <FiltradoCarros
                            applyFilter={this.applyFilter}
                            priceFilter={this.priceFilter}
                            pasajeroFilter={this.pasajeroFilter}
                            kilometrajeFilter={this.kilometrajeFilter}
                            marcaFilter={this.marcaFilter}
                            values={this.state}
                            tipos={clases}
                            marcas={marcas}
                            modelos={modelos}
                            annos={annos}
                            transmisiones={transmisiones}
                            combustibles={combustibles}
                            colores={colores}
                            zonas={zonas}
                        />
                    </Box>
                    {cars.length > 0 ?
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={"10"} p={"16px"} width={{ base: 'full', lg: '75%' }}>
                            {cars.map(car =>
                                <CarCard car={car} key={car.id} pickCar={this.pickCar} />
                            )}
                        </SimpleGrid>
                        :
                        <Box width={{ base: 'full', lg: '75%' }} p={3} textAlign={"center"}>
                            <Image src={empty} alt="Vacio" m={"auto"} maxH={"400px"} />
                            <Text>El Carro que buscas no se encuentra disponible, busca nuevamente</Text>
                            <Button size={"sm"} variant='outline' colorScheme={"green"} onClick={this.clearFliter} >Limpiar filtros</Button>
                        </Box>
                    }
                </Flex>
            </Layout>
        )
    }

}

const mapStateToProps = (state) => ({
    cars: getCars(state),
    clases: getEntity(state, "clase"),
    marcas: getEntity(state, "marca"),
    modelos: getEntity(state, "modelo"),
    annos: getEntity(state, "anno-vehiculo"),
    combustibles: getEntity(state, "combustible"),
    transmisiones: getEntity(state, "transmision"),
    colores: getEntity(state, "color"),
    zonas: getEntity(state, "zona"),
})

const mapDispatchToProps = { fetchCars, fetchFilter, fetchFilterWithId, filterCar, filterBar, selectCar, fetchEntity, fetchEntityWithId }

export default connect(mapStateToProps, mapDispatchToProps)(Cars)