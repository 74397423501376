const solicitudes = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_SOLICITUDES': return action.solicitudes
        case 'FETCH_SOLICITUD':
            return state.map(solicitud => {
                if (action.solicitud.id === solicitud.id){
                    return action.solicitud
                } else{
                    return solicitud
                }
            })
        case 'LOG_OUT': return []
        default: return state
    }
}

export const getSolicitudes = (state) => state
export const getSolicitud = (state, id) => state.reduce((p, solicitudes) => {
    if (solicitudes.id === Number(id)) return solicitudes
    return p
}, {})

export default solicitudes