import React from 'react'
import { connect } from 'react-redux'
import { fetchEntityWithId } from '../actions'
import { getEntity } from '../reducers'
import {
    Flex,
    FormControl,
    FormLabel,
    Input, Select,
    FormErrorMessage,
    Button,
    CircularProgress,
    Box,
    HStack
} from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { FormValidator } from '../utils/validationForms'

const Municipio = (props) => {
    const { values: { departamento }, touched, setFieldValue } = useFormikContext();
    const { fetchEntityWithId } = props

    React.useEffect(() => {
        if (departamento !== "") fetchEntityWithId('municipio', departamento)
    }, [departamento, touched.departamento, setFieldValue, fetchEntityWithId])

    return <div>{props.children}</div>
};

const FormularioDatosPersonales = (props) => {

    const { setDataClient, nombres, nombres2, apellidos, apellidos2, genero, zona_residencial, cDepartamento, departamentos, cMunicipio, municipios, fetchEntityWithId } = props

    return (
        <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} mt={6} mb={6}>
            <Box rounded={'lg'} bg={"white"} boxShadow={'lg'} p={8} w={"full"} maxW={"900px"}>
                <Formik
                    initialValues={{
                        nombres: nombres,
                        nombres2: nombres2,
                        apellidos: apellidos,
                        apellidos2: apellidos2,
                        genero: genero,
                        zona_residencial: zona_residencial,
                        departamento: cDepartamento,
                        municipio: cMunicipio,
                        terminos: false
                    }}
                    validate={values => FormValidator([
                        { field: 'nombres', value: values.nombres, rules: ['required'], lang: 'Primer Nombre' },
                        { field: 'apellidos', value: values.apellidos, rules: ['required'], lang: 'Primer Apellido' },
                        { field: 'zona_residencial', value: values.zona_residencial, rules: ['required'], lang: 'Zona residencial' },
                        { field: 'departamento', value: values.departamento, rules: ['required'], lang: 'Departamento' },
                        { field: 'municipio', value: values.municipio, rules: ['required'], lang: 'Municipio' },
                        { field: 'genero', value: values.genero, rules: ['required'], lang: 'Género' },
                    ])}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        Promise.resolve(setDataClient(values))
                            .then(setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>

                            <HStack>
                                <FormControl isInvalid={errors.nombres && touched.nombres}>
                                    <FormLabel>Primer Nombre</FormLabel>
                                    <Input type="text" name='nombres' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombres} />
                                    <FormErrorMessage>{errors.nombres}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.nombres2 && touched.nombres2}>
                                    <FormLabel>Segundo Nombre</FormLabel>
                                    <Input type="text" name='nombres2' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombres2} />
                                    <FormErrorMessage>{errors.nombres2}</FormErrorMessage>
                                </FormControl>

                            </HStack>

                            <HStack>
                                <FormControl isInvalid={errors.apellidos && touched.apellidos}>
                                    <FormLabel>Primer Apellido</FormLabel>
                                    <Input type={'text'} size="lg" name={"apellidos"} onChange={handleChange} onBlur={handleBlur} value={values.apellidos} />
                                    <FormErrorMessage>{errors.apellidos}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.apellidos2 && touched.apellidos2}>
                                    <FormLabel>Segundo Apellido</FormLabel>
                                    <Input type={'text'} size="lg" name={"apellidos2"} onChange={handleChange} onBlur={handleBlur} value={values.apellidos2} />
                                    <FormErrorMessage>{errors.apellidos2}</FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <FormControl isInvalid={errors.genero && touched.genero}>
                                <FormLabel>Género</FormLabel>
                                <Select name={"genero"} onChange={handleChange} onBlur={handleBlur} value={values.genero} >
                                    <option value={""}>Seleccione género</option>
                                    <option value={"No mencionado"}>No mencionado</option>
                                    <option value={"Masculino"}> Masculino</option>
                                    <option value={"Femenino"}> Femenino</option>
                                </Select>
                                <FormErrorMessage>{errors.genero}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.zona_residencial && touched.zona_residencial}>
                                <FormLabel>Zona residencial</FormLabel>
                                <Select name={"zona_residencial"} onChange={handleChange} onBlur={handleBlur} value={values.zona_residencial} >
                                    <option value={""}>Seleccione zona</option>
                                    <option value={"Zona Litoral"}>Zona Litoral</option>
                                    <option value={"Zona occidental"}>Zona occidental</option>
                                    <option value={"Zona Norte"}>Zona Norte</option>
                                    <option value={"Zona centro - norte"}>Zona centro - norte</option>
                                    <option value={"Zona Centro"}>Zona Centro</option>
                                    <option value={"Zona oriental - sur"}>Zona oriental - sur</option>
                                    <option value={"Zona Sur"}>Zona Sur</option>
                                    <option value={"Zona oriental - norte"}>Zona oriental - norte</option>
                                </Select>
                                <FormErrorMessage>{errors.zona_residencial}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.departamento && touched.departamento}>
                                <FormLabel>Departamento</FormLabel>
                                <Select name={"departamento"} onChange={handleChange} onBlur={handleBlur} value={values.departamento} >
                                    <option value={""}>Seleccione departamento</option>
                                    {departamentos.filter(departamento => {
                                        if (values.zona_residencial === departamento.ZonaResidencial) return true
                                        return false
                                    }
                                    ).map(departamento =>
                                        <option value={departamento.CodDepartamento} key={departamento.CodDepartamento}> {departamento.Departamento} </option>
                                    )}
                                </Select>
                                <FormErrorMessage>{errors.departamento}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.municipio && touched.municipio}>
                                <Municipio departamentosList={departamentos} fetchEntityWithId={fetchEntityWithId}>
                                    <FormLabel>Municipio</FormLabel>
                                    <Select name={"municipio"} onChange={handleChange} onBlur={handleBlur} value={values.municipio}  >
                                        <option value={""}>Seleccione municipio</option>
                                        {municipios.map(municipio =>
                                            <option value={municipio.CodMunicipio} key={municipio.CodMunicipio}> {municipio.Municipio} </option>
                                        )}
                                    </Select>
                                    <FormErrorMessage>{errors.municipio}</FormErrorMessage>
                                </Municipio>
                            </FormControl>

                            <Button w={"full"} colorScheme="easycar2" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Continuar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Flex>
    )
}


const mapStateToProps = (state) => ({
    departamentos: getEntity(state, 'departamento'),
    municipios: getEntity(state, 'municipio')
})

const mapDispatchToProps = { fetchEntityWithId }

export default connect(mapStateToProps, mapDispatchToProps)(FormularioDatosPersonales)