import { Carousel } from 'react-carousel-minimal'
import { Flex, Box, Heading, Text, Button } from '@chakra-ui/react'
import banner1 from '../multimedia/banners/b0.jpg'
import banner2 from '../multimedia/banners/b1.jpg'
import banner3 from '../multimedia/banners/b2.jpg'
import { Link } from 'react-router-dom'

export default function HeroBanner3() {

    const images = [
        { image: banner1 },
        { image: banner2 },
        { image: banner3 }
    ]

    return (
        <Flex w={"full"} flexDir={{ base: 'column', md: 'row' }}>
            <Box order={{ base: 2, md: 1 }} className={'sideLeft'} bg={"white"} w={"full"} maxW={{ base: "full", md: "40%" }} pl={"5%"} m={"auto"} >
                <Heading>Garantizamos tu experiencia en la compra, financiamiento y venta de vehículos</Heading>
                <Text as={"b"}>Descubre nuestros vehículos de la más alta calidad</Text>
                <br />
                <Link to={"/cars"}>
                    <Button colorScheme={"easycar2"} >Compra tu vehículo</Button>
                </Link>
                <br />
                <br/>
                <Link to={"/vende"}>
                    <Text as={"b"}>Vende o intercambia tu vehículo ahora</Text>
                </Link>
            </Box>
            <Box order={{ base: 1, md: 2 }} className={'sideRight'} w={"full"} maxW={{ base: "full", md: "60%" }} >
                <Carousel
                    data={images}
                    time={4000}
                    width={"100%"}
                    height={"600px"}
                    slideNumber={false}
                    captionPosition="bottom"
                    automatic={true}
                    dots={false}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={false}
                />
            </Box>
        </Flex>
    )
}